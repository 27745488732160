import { useMediaQuery, useTheme } from '@mui/material';

import { Asset, Maybe, Video } from '@/types/generated';
import VideoContent from '@/components/VideoContent';

export interface IVideoAsset {
	/**
	 * Required field for the Desktop Video
	 *
	 * @type {Video}
	 * @memberof IVideoAsset
	 */
	desktopVideo: Video;
	/**
	 * Optional field for the Mobile Video
	 *
	 * @type {Video}
	 * @memberof IVideoAsset
	 */
	mobileVideo?: Video;
	/**
	 * Poster Image for the video
	 *
	 * @type {Maybe<Asset>}
	 * @memberof IVideoAsset
	 * @todo rename this to isBackgroundImage
	 */
	posterImage?: Maybe<Asset>;
	/**
	 * Optional styles to apply to the video.
	 *
	 * @type {React.CSSProperties}
	 * @memberof IVideoAsset
	 */
	videoSx?: React.CSSProperties;
	/**
	 * Optional data-test-id attribute for testing purposes
	 *
	 * @type {string}
	 * @memberof IVideoAsset
	 */
	dataTestId?: string;
	/**
	 * When this is true, video will be previewed for 3 seconds and in loop
	 *
	 * @type {string}
	 * @memberof IVideoAsset
	 */
	loadVideoInPreviewMode?: boolean;
	/**
	 * Optional styles to apply to the video controls.
	 *
	 * @type {React.CSSProperties}
	 * @memberof IVideoAsset
	 */
	videoControlsSx?: React.CSSProperties;
	/**
	 * Is the video going to be rendered in the hero or not?.
	 *
	 * @type {boolean}
	 * @memberof IVideoAsset
	 */
	fromHero?: boolean;
}

export default function VideoAsset({
	desktopVideo,
	mobileVideo,
	posterImage,
	videoSx,
	dataTestId,
	videoControlsSx = {},
	loadVideoInPreviewMode = false,
	fromHero = false,
}: IVideoAsset) {
	const theme = useTheme();
	const isMobileScreen = useMediaQuery(theme.breakpoints.down('md'));

	const videoToDisplay = isMobileScreen && mobileVideo ? mobileVideo : desktopVideo;
	const mobileSrc = mobileVideo?.media?.url;
	const desktopSrc = desktopVideo?.media?.url;
	return (
		<VideoContent
			controllableVideo={videoToDisplay}
			posterImage={posterImage}
			videoElSx={videoSx}
			dataTestId={dataTestId}
			videoControlsSx={videoControlsSx}
			loadVideoInPreviewMode={loadVideoInPreviewMode}
			fromHero={fromHero}
		>
			{mobileSrc ? (
				<source
					src={mobileSrc}
					media={desktopSrc ? theme.breakpoints.down('md').replace('@media', '').trim() : undefined}
				/>
			) : null}
			{desktopSrc ? <source src={desktopSrc} /> : null}
		</VideoContent>
	);
}
